import { useEffect, useState } from 'react';
import './App.css';

// MUI components.
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Components
import Header from './components/Header';
import ArticleCard from './components/ArticleCard';
import Footer from './components/Footer';

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  typography: {
    fontFamily: [
      'Quicksand',
      'sans-serif'
    ].join(',')
  }
});

const API_URL_NEWS = 'https://solomon-islands-news-scraper.onrender.com/news';
const API_URL_SOURCES = 'https://solomon-islands-news-scraper.onrender.com/newssources';

const App = () => {
  const [articles, setArticles] = useState([]);
  const [sources, setSources] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [checked, setChecked] = useState([]);

  const filterArticles = async (e) => {
    let updatedSources = [...checked];

    if (e.target.checked) {
      updatedSources = [...checked, e.target.value];
    } else {
      updatedSources.splice(checked.indexOf(e.target.value), 1);
    }

    setChecked(updatedSources);

    let sortedArticles = [];

    updatedSources.forEach(sourceID => {
      articles.forEach(article => {
        if (article.sourceID === sourceID) {
          sortedArticles.push(article);
        }
      });
    });

    if (updatedSources.length > 0) {
      setFilteredArticles(sortedArticles);
    } else {
      setFilteredArticles(articles);
    }
  };

  const getArticles = async () => {
    const response = await fetch(API_URL_NEWS);
    const data = await response.json();

    // Sort articles by ascending dates.
    data.sort((a, b) => {
      return new Date(b.published) - new Date(a.published);
    });

    setArticles(data);
  }

  const getSources = async () => {
    const response = await fetch(API_URL_SOURCES);
    const data = await response.json();

    setSources(data);
  }

  useEffect(() => {
    getArticles();
    getSources();

    setFilteredArticles(articles);
  }, [JSON.stringify(articles)]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <Header newsSources={sources} />

        <Container maxWidth="lg">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter articles by news sources</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                {
                  sources?.length > 0
                    ? (
                      sources.map((source) => (
                        <FormControlLabel key={source.sourceID} control={<Checkbox />} onChange={filterArticles} value={source.sourceID} label={source.source} />
                      ))
                    ) : (
                      <Grid container>
                        <Grid item xs={12} sx={{ display: 'flex', height: '70vh' }}>
                          <CircularProgress sx={{ margin: 'auto' }} />
                        </Grid>
                      </Grid>
                    )
                }
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </Container>

        <Container maxWidth="lg">
          <Typography variant='caption' sx={{ display: 'block', margin: '15px 0px 30px' }}>Showing {filteredArticles.length} articles</Typography>
        </Container>

        <Container maxWidth="lg" className='no-padding'>
          {
            articles?.length > 0
              ? (
                <Grid container spacing={2}>
                  {
                    filteredArticles.map((article) => (
                      <Grid key={article.ID} item xs={12} sm={6} md={4} lg={3} className="small-padding-top">
                        <ArticleCard key={article.ID} article={article} />
                      </Grid>
                    ))
                  }
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} sx={{ display: 'flex', height: '70vh' }}>
                    <CircularProgress sx={{ margin: 'auto' }} />
                  </Grid>
                </Grid>
              )
          }
        </Container>
        <Footer />
      </div>
    </ThemeProvider >
  );
}

export default App;
