import { Link } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <footer>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sx={{ padding: '15px 0px' }} xs={12}>
            <Typography variant='body2'>
              Copyright © {new Date().getFullYear()} <Link href="/">solomonstoday.com</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;