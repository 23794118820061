import * as React from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import Link from '@mui/material/Link';

const limit = (string = '', limit = 0) => {
  return string.substring(0, limit)
}

const ArticleCard = ({ article }) => {
  return (
    <Link href={article.url} underline="none">
      <Card className="full-width no-radius" sx={{ maxWidth: 345, margin: 'auto' }} raised={true}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="200"
            image={article.imgURL}
            alt={article.title}
          />
          <CardContent sx={{ padding: '0px' }}>
            <Grid item sx={{ padding: '15px' }}>
              <Typography gutterBottom variant='body1' sx={{ fontWeight: '700' }}>
                {article.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {limit(article.excerpt, 100)}...
              </Typography>
            </Grid>

            <Divider sx={{ margin: '5px 0px' }} />

            <Grid container sx={{ padding: '13px 15px' }}>
              <Grid container sx={{ position: 'relative', left: '-3px' }}>
                <Grid item xs={1}>
                  <CalendarMonthOutlinedIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" color="text.primary" sx={{ padding: '2px 0px 0px 8px' }}>
                    {article.published}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="caption" color="text.primary">
                  Source: {article.source}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

export default ArticleCard;