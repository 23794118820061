import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AboutDrawer from './AboutDrawer';

const Header = ({newsSources}) => {
  return (
    <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            SolomonsToday
          </Typography>
          <AboutDrawer newsSources={newsSources} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;